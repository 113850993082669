import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { TwitchPlayer, TwitchChat } from "react-twitch-embed";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <TwitchPlayer
            channel="mlgstreamers"
            height="100vh"
            width="75%"
            parent={["mlgstreamers.com", "rosier.me", "raptosaur.github.io"]}
            theme="dark"
          />
          <TwitchChat
            style={{
              position: "fixed",
              right: 0,
              top: 0,
              height: "50vh",
              width: "25%",
            }}
            channel="mlgstreamers"
            parent={["mlgstreamers.com", "rosier.me", "raptosaur.github.io"]}
            theme="dark"
          />
          <div id="chatango"></div>
        </Route>
        <Route path="*">
          <h1>404</h1>
          <p>Page could not be found.</p>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
